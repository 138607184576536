import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { NxModule } from '@nrwl/angular';
import { PerfectScrollbarConfig, WINDOW, windowFactory } from '@page2flip/core';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';

import { AppComponent } from './app.component';
import { routes } from './app.routes';
import { BaseModule } from './base/base.module';
import { bugsnagFactory } from './bugsnag.factory';
import { AuthenticationInterceptor } from './core/interceptors/authentication.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
registerLocaleData(localeDe, 'de-DE'); // FIXME

@NgModule({
  bootstrap: [
    AppComponent
  ],
  declarations: [
    AppComponent
  ],
  imports: [
    BaseModule,
    NxModule.forRoot(),
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    StoreModule.forRoot({}, {}),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: ErrorHandler,
      useFactory: bugsnagFactory,
    },
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-DE',
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: PerfectScrollbarConfig,
    },
    {
      provide: WINDOW,
      useFactory: windowFactory,
    }
  ]
})
export class AppModule { }
